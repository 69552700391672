<template>
  <div class="pd-20">
    <a-page-header style="padding: 0" sub-title="">
      <div slot="title">
        <h1>
          <a-icon class="gradient" type="credit-card" /> Cartão de Crédito
          Corporativo
        </h1>
      </div>
      <div slot="extra">
        <a-button type="primary" ghost @click="openCreateCCard = true">
          <a-icon type="credit-card" /> NOVO CARTÃO
        </a-button>
      </div>
    </a-page-header>

    <a-tabs type="card" :default-active-key="activeTab" @change="onChangeTabs">
      <a-tab-pane key="Ativo" tab="ATIVO" force-render> </a-tab-pane>
      <a-tab-pane key="Desativado" tab="DESATIVADO" force-render> </a-tab-pane>
    </a-tabs>

    <a-collapse
      class="travel-filters expandable mb-10"
      activeKey="0"
      expandIconPosition="right"
    >
      <a-collapse-panel key="1">
        <template slot="header">
          <a-icon type="filter" class="mr-5" /> FILTRAR
        </template>
        <a-row class="mt-0 mb-0" :gutter="20">
          <a-col :span="2">
            <div class="travel-input">
              <label class="filled">ID </label>
              <a-input
                placeholder="Digite"
                v-model="companyCreditCards.filters.id"
                @change="getCompanyCreditCards()"
              />
            </div>
          </a-col>

          <a-col v-if="$root.isAdmin()" :span="4">
            <div class="travel-input">
              <label class="filled">Usuário</label>
              <a-select
                class="travel-input"
                placeholder="Selecione um ou mais usuários"
                mode="multiple"
                optionFilterProp="txt"
                v-model="companyCreditCards.filters.users.selected"
                show-search
                style="width: 100%"
                @change="getCompanyCreditCards()"
              >
                <a-select-option
                  v-for="(item, index) of companyCreditCards.filters.users.list"
                  :key="index"
                  :value="item.id"
                  :txt="item.first_name"
                >
                  {{ item.id }} - {{ item.first_name }}
                  {{ item.last_name }}
                </a-select-option>
              </a-select>
            </div>
          </a-col>
        </a-row>
      </a-collapse-panel>
    </a-collapse>

    <a-table
      class="travel-table"
      :columns="columns"
      :data-source="companyCreditCards.list"
      :loading="companyCreditCards.loading"
      :pagination="false"
      :scroll="{ x: 1300 }"
      @change="companyCreditCardsTableChange"
    >
      <div slot="id" slot-scope="text">{{ text }}</div>
      <div slot="voyage_id" slot-scope="text">
        <div v-if="text != '0'">{{ text }}</div>
        <div v-if="text == '0'">---</div>
      </div>
      <div slot="customer" slot-scope="record">
        {{ record.customer.first_name }} {{ record.customer.last_name }}
      </div>
      <div slot="name" slot-scope="record">
        <div v-if="record.person_type == 'Pessoa Física'">
          {{ record.first_name }} {{ record.last_name }}
        </div>
        <div v-if="record.person_type == 'Pessoa Jurídica'">
          {{ record.trading_name }}
        </div>
      </div>

      <div slot="bank" slot-scope="record">
        {{ record.bank.name }}
      </div>

      <div slot="person_type" slot-scope="record">
        {{ record.customer.person_type }}
      </div>

      <div slot="card_limit" slot-scope="record">
        {{ formatPricePtBr(record) }}
      </div>
      <div slot="available_value" slot-scope="record">
        {{ formatPricePtBr(record) }}
      </div>
      <div slot="spent_value" slot-scope="record">
        {{ formatPricePtBr(record) }}
      </div>
      <span class="travel-actions" slot="action" slot-scope="record">
        <a-tooltip placement="top" title="Editar">
          <a class="edit" @click="edit(record.id)">
            <a-icon type="edit-svg" />
          </a>
        </a-tooltip>
        <a-popconfirm
          v-if="$root.isAdmin()"
          title="Tem certeza que deseja apagar?"
          ok-text="Sim"
          placement="left"
          cancel-text="Não"
          @confirm="confirmDelete(record.id)"
        >
          <a-icon slot="icon" type="question-circle-o" style="color: red" />

          <a class="ml-15 delete">
            <a-icon type="delete" />
          </a>
        </a-popconfirm>
      </span>
      <template slot="action" slot-scope="record">
        <div class="actions-outer" style="position: relative">
          <a class="edit ml-15" @click="edit(record.id)">
            <a-icon slot="prefix" :type="'eye-svg'" />
          </a>
        </div>
      </template>
    </a-table>

    <div class="a-center mt-30" style="padding-bottom: 100px">
      <a-pagination
        show-size-changer
        :default-current="companyCreditCards.pagination.page"
        :total="companyCreditCards.pagination.total"
        @change="changeCompanyCreditCardsPage"
        @showSizeChange="changeCompanyCreditCardsPageSize"
      />
    </div>

    <a-modal
      class="miles-card-modal"
      v-model="viewCompanyCreditCard"
      width="1000px"
      :footer="false"
      @cancel="closeCreditCardModal"
    >
      <div v-show="loadingModal">
        <a-skeleton active />
        <a-skeleton active />
        <a-skeleton active />
        <a-skeleton active />
      </div>
      <CompanyCreditCardsModal
        v-show="!loadingModal"
        :creditCardId="selectedCompanyCreditCardId"
        @loadStatus="loadStatus"
        v-if="viewCompanyCreditCard"
      />
    </a-modal>

    <a-drawer
      title="NOVO CARTÃO DE CRÉDITO"
      placement="right"
      width="640px"
      class="travel-drawer"
      :closable="true"
      :visible="openCreateCCard"
      @close="openCreateCCard = false"
    >
      <EditCompanyCreditCards
        v-if="openCreateCCard"
        type="create"
        :creditCard="{}"
        :fieldSizes="{
          bank_id: 24,
          card_name: 12,
          card_number: 12,
          card_month: 6,
          card_year: 6,
          card_cvv: 12,
          card_limit: 12,
          status: 12,
          invoice_closing_date: 12,
          invoice_payment_date: 12,
          customer_id: 12,
          create_customer: 2,
        }"
        :viewOnly="false"
        @listCompanyCreditCardsAfterCreate="listCompanyCreditCardsAfterCreate"
      />
    </a-drawer>
  </div>
</template>

<script>
import formatThings from "@/mixins/general/formatThings";
import companyCreditCardMixins from "@/mixins/company-credit-cards/companyCreditCardMixins.js";
import EditCompanyCreditCards from "@/components/company-credit-cards/forms/EditCompanyCreditCards.vue";
import CompanyCreditCardsModal from "@/components/company-credit-cards/modal/CompanyCreditCardsModal.vue";
import banks from "@/json/banks.json";

export default {
  name: "ListCompanyCreditCards",
  mixins: [formatThings, companyCreditCardMixins],
  components: { EditCompanyCreditCards, CompanyCreditCardsModal },
  data() {
    return {
      activeTab: "Ativo",
      openCreateCCard: false,
      viewCompanyCreditCard: false,
      selectedCompanyCreditCardId: 0,
      banks,
      loadingModal: true,
      columns: [
        {
          title: "ID",
          dataIndex: "id",
          key: "id",
          width: 100,
        },
        {
          title: "Proprietário do cartão",
          scopedSlots: { customRender: "customer" },
          width: 300,
        },
        {
          title: "Banco",
          scopedSlots: { customRender: "bank" },
        },
        {
          title: "Tipo de pessoa",
          scopedSlots: { customRender: "person_type" },
        },
        {
          title: "Limite do Cartão",
          dataIndex: "card_limit",
          key: "card_limit",
          scopedSlots: { customRender: "card_limit" },
          sorter: true,
        },
        {
          title: "Disponível",
          dataIndex: "available_value",
          key: "available_value",
          scopedSlots: { customRender: "available_value" },
          sorter: true,
        },
        {
          title: "Utilizado",
          dataIndex: "spent_value",
          key: "spent_value",
          scopedSlots: { customRender: "spent_value" },
          sorter: true,
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  beforeMount() {
    this.$http.get("/user/list?page=1&per_page=100").then(({ data }) => {
      this.companyCreditCards.filters.users.list = data.data;
    });
    this.companyCreditCards.filters.status.selected = this.activeTab;
    this.getCompanyCreditCards();
    document.title = "Cartões de Crédito Corporativos - HAYA";
  },
  methods: {
    closeCreditCardModal() {
      this.selectedCompanyCreditCardId = 0;
      this.loadingModal = true;
    },
    loadStatus(val) {
      this.loadingModal = val;
    },
    listCompanyCreditCardsAfterCreate() {
      this.openCreateCCard = false;
      this.getCompanyCreditCards();
    },
    getCustomers(name) {
      this.$http
        .get(`/customer/list?page=1&per_page=10&s=${name}`)
        .then(({ data }) => {
          this.companyCreditCards.filters.customers.list = data.data;
        });
    },
    searchCustomers(term) {
      this.getCustomers(term);
    },
    onChangeTabs(tab) {
      this.activeTab = tab;
      this.companyCreditCards.filters.status.selected = this.activeTab;
      this.getCompanyCreditCards();
    },
    listVouchers() {
      this.openCreateCCard = false;
      this.getCompanyCreditCards();
    },
    edit(id) {
      this.viewCompanyCreditCard = true;
      this.selectedCompanyCreditCardId = id;
    },
    changePage(current) {
      this.companyCreditCards.pagination.page = current;
      this.getCompanyCreditCards();
    },
    changePageSize(current, pageSize) {
      this.companyCreditCards.pagination.page = current;
      this.companyCreditCards.pagination.perPage = pageSize;
      this.getCompanyCreditCards();
    },
  },
};
</script>
